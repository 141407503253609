<template>
  <div class="caseDataInfo" v-if="isShow">
    <div class="infoOne">
      <el-form
        :model="caseDataInfo"
        :rules="rulesCaseDataInfo"
        ref="rulesCaseDataInfo"
        label-width="140px"
      >
        <!-- <el-form-item label="标准名称：" prop="standardName">
          <el-input v-model="caseDataInfo.standardName" class="lager-input" />
        </el-form-item> -->
        <el-form-item label="标准号：" prop="standardId">
          <el-input v-model="caseDataInfo.standardId" class="lager-input" />
        </el-form-item>
        <el-form-item label="简介：" prop="standardIntro">
          <el-input
            v-model="caseDataInfo.standardIntro"
            class="lager-input"
            :autosize="{ minRows: 4, maxRows: 20 }"
            type="textarea"
          />
        </el-form-item>
        <el-form-item label="标准语言：" prop="standardLanguage">
          <el-input
            v-model="caseDataInfo.standardLanguage"
            class="lager-input"
          />
        </el-form-item>
        <el-form-item label="发布日期：">
          <data-time
            @dateChange="dateClick"
            :parentYear="Number(caseDataInfo.publishDateYear)"
            :parentMonth="Number(caseDataInfo.publishDateMonth)"
            :parentDay="Number(caseDataInfo.publishDateDay)"
          />
        </el-form-item>
        <el-form-item label="标准来源：" prop="standardSource">
          <el-input v-model="caseDataInfo.standardSource" class="lager-input" />
        </el-form-item>
        <el-form-item label="标准发布组织：" prop="publishOrganization">
          <el-input
            v-model="caseDataInfo.publishOrganization"
            class="lager-input"
          />
        </el-form-item>
        <el-form-item label="标准类型：">
          <dic-checkbox-group
            :code.sync="caseDataInfo.standardTypeList"
            type-code="045"
          />
        </el-form-item>
        <el-form-item label="标准状态：">
          <dic-radio-group
            :code.sync="caseDataInfo.standardStatus"
            type-code="042"
          />
        </el-form-item>
        <el-form-item label="国际标准分类：">
          <dic-checkbox-group
            :code.sync="caseDataInfo.internationalStandardClassificationList"
            tag="国际标准知识"
            @updateCode="updateCode"
          />
        </el-form-item>
        <el-form-item label="领域标签：" v-if="personType">
          <el-tag
            v-for="(item, index) in this.caseDataInfo.fieldCategory"
            :key="index"
            >{{ item }}</el-tag
          >
        </el-form-item>
        <el-form-item label="技术标签：" v-if="personType">
          <el-tag
            v-for="(item, index) in this.caseDataInfo.techCategory"
            :key="index"
            >{{ item }}</el-tag
          >
        </el-form-item>
        <el-form-item label="上传附件：">
          <div class="policyData">
            <el-input v-model="caseDataInfo.fileNames" class="lager-input">
              <el-button
                slot="append"
                icon="el-icon-download"
                @click="downloadFile"
                v-if="caseDataInfo.fileNames"
              ></el-button>
            </el-input>
            <upload-file
              @upFileKey="fileKey"
              @fileSucee="successUpload"
              style="margin-left: 4px"
            />
          </div>
        </el-form-item>
      </el-form>
      <div class="ess_people" v-if="personType">
        <div class="ess_people_list">创建人：{{ caseDataInfo.createName }}</div>
        <div class="ess_people_list">修改人：{{ caseDataInfo.updateName }}</div>
        <div class="ess_people_list">
          创建时间：{{ caseDataInfo.createTime }}
        </div>
        <div class="ess_people_list">
          修改时间：{{ caseDataInfo.updateTime }}
        </div>
      </div>
    </div>
    <div class="bottom">
      <el-button type="primary" @click="saveItem">保存</el-button>
      <el-button @click="cancelItem">取消</el-button>
    </div>
  </div>
</template>

<script>
import UpLoadImage from "../../Product/UpLoadImage";
import uploadFile from "../../PolicyData/uploadFile";
import DicRadioGroup from "@/components/DicRadioGroup.vue";
import {
  getForeignStandardOne,
  insertForeignStandard,
  updateForeignStandard,
} from "@/api/standardKnowledge";
import DataTime from "@/components/DataTime.vue";
import DicCheckboxGroup from "@/components/DicCheckboxGroup.vue";
import axios from "axios";
import { URL } from "../../../../config";
export default {
  name: "CaseDataInfo",

  components: {
    DataTime,
    DicCheckboxGroup,
    DicRadioGroup,
    UpLoadImage,
    uploadFile,
  },

  watch: {
    "caseDataInfo.id": {
      handler(newVal) {
        if (this.caseDataInfo && newVal) {
          this.isShow = true;
        }
      },
      deep: true,
      immediate: true,
    },
  },

  data() {
    return {
      inputVisible: false,
      isShow: false,
      optionsAssociation: [],
      caseDataInfo: {
        standardName: "", //标准名称
        standardLanguage: "", //标准语言
        standardId: "", //标准号
        standardIntro: "", //简介
        standardSource: "", //标准来源
        publishDate: "", //发布日期
        publishOrganization: "", //标准发布组织
        technicalCommittees: "", //归口单位
        competentDepartment: "", //主管部门
        standardTypeList: [], //标准类型
        standardStatus: "", //标准状态
        industryCategoryList: [], //行业类别
        industryCategoryListChild: [], //行业类别子类别
        internationalStandardClassificationList: [], //国际标准分类
        internationalStandardClassificationListChild: [], //国际标准分类子类别
        draftingUnit: "", //起草单位
        drafterList: [], //起草人
        fileName: "", //上传文件名
        fileNames: "",
      },
      inputValue: "",
      imageList: [],
      options: [],
      options1: [],
      rulesCaseDataInfo: {
        standardName: [
          { required: true, message: "请输入标准名称", trigger: "blur" },
        ],
        standardId: [
          { required: true, message: "请输入标准号", trigger: "blur" },
        ],
      },
      loading: false,
    };
  },

  computed: {
    personType() {
      return Number(this.$route.query.personType);
    },
    policyId() {
      return Number(this.$route.query.id);
    },
    editInfo() {
      return this.$route.params.editInfo;
    },
    Authorization() {
      return sessionStorage.getItem("token");
    },
  },

  created() {
    if (this.personType) {
      this.search();
    }
    if (!this.personType) {
      this.isShow = true;
    }
  },

  methods: {
    async search() {
      const data = {
        id: this.policyId,
      };
      const res = await getForeignStandardOne(data);
      if (res.code == 200) {
        this.caseDataInfo = res.data;
        if (this.caseDataInfo.industryCategory) {
          this.caseDataInfo.industryCategoryList =
            this.caseDataInfo.industryCategory.split(",");
        } else {
          this.caseDataInfo.industryCategoryList = [];
        }

        if (this.caseDataInfo.internationalStandardClassification) {
          this.caseDataInfo.internationalStandardClassificationList =
            this.caseDataInfo.internationalStandardClassification.split(",");
        } else {
          this.caseDataInfo.internationalStandardClassificationList = [];
        }

        if (this.caseDataInfo.fileName) {
          this.caseDataInfo.fileNames = this.caseDataInfo.fileName.substring(
            this.caseDataInfo.fileName.lastIndexOf("/") + 1
          );
        }
        if (this.caseDataInfo.drafter) {
          this.caseDataInfo.drafterList = this.caseDataInfo.drafter.split(",");
        } else {
          this.caseDataInfo.drafterList = [];
        }
        if (this.caseDataInfo.fieldCategory) {
          this.caseDataInfo.fieldCategory =
            this.caseDataInfo.fieldCategory.split(",");
        } else {
          this.caseDataInfo.fieldCategory = [];
        }
        if (this.caseDataInfo.techCategory) {
          this.caseDataInfo.techCategory =
            this.caseDataInfo.techCategory.split(",");
        } else {
          this.caseDataInfo.techCategory = [];
        }
        if (this.caseDataInfo.standardType) {
          this.caseDataInfo.standardTypeList =
            this.caseDataInfo.standardType.split(",");
        } else {
          this.caseDataInfo.standardType = [];
        }
      } else {
        this.$message.error("查询失败");
      }
    },
    formatNull(val) {
      if (val === null) {
        return [];
      } else {
        return val;
      }
    },
    // 将字符串格式化成数组
    formatString(val) {
      if (!val) {
        return [];
      } else if (!val.length) {
        return val;
      } else {
        return val.split(",");
      }
    },
    updateCode(val, tag) {
      switch (tag) {
        case "技术":
          this.caseDataInfo.internationalStandardClassificationListChild = val;
          break;

        case "行业":
          this.caseDataInfo.industryCategoryListChild = val;
          break;

        case "国际标准知识":
          this.caseDataInfo.internationalStandardClassificationListChild = val;
          break;

        default:
          break;
      }
    },
    dateClick(year, month, day) {
      this.caseDataInfo.publishDateYear = year;
      this.caseDataInfo.publishDateMonth = month;
      this.caseDataInfo.publishDateDay = day;
    },
    dateClickOne(year, month, day) {
      this.caseDataInfo.implementationDateYear = year;
      this.caseDataInfo.implementationDateMonth = month;
      this.caseDataInfo.implementationDateDay = day;
    },
    // 保存
    saveItem() {
      this.$refs.rulesCaseDataInfo.validate(async (valid) => {
        if (valid) {
          if (this.caseDataInfo.internationalStandardClassificationListChild) {
            this.caseDataInfo.internationalStandardClassificationList =
              this.caseDataInfo.internationalStandardClassificationList.concat(
                this.caseDataInfo.internationalStandardClassificationListChild
              );
          }
          if (this.caseDataInfo.industryCategoryListChild) {
            this.caseDataInfo.industryCategoryList =
              this.caseDataInfo.industryCategoryList.concat(
                this.caseDataInfo.industryCategoryListChild
              );
          }
          if (this.caseDataInfo.fieldCategory) {
            this.caseDataInfo.fieldCategory =
              this.caseDataInfo.fieldCategory.join(",");
          } else {
            this.caseDataInfo.fieldCategory = "";
          }
          if (this.caseDataInfo.techCategory) {
            this.caseDataInfo.techCategory =
              this.caseDataInfo.techCategory.join(",");
          } else {
            this.caseDataInfo.techCategory = "";
          }
          let res;
          if (this.personType) {
            res = await updateForeignStandard(this.caseDataInfo);
            if (res.code == 200) {
              this.$message.success("编辑成功");
              this.$router.go(-1);
            } else {
              this.$message.error("编辑失败");
            }
          } else {
            res = await insertForeignStandard(this.caseDataInfo);
            if (res.code == 200) {
              this.$message.success("新增成功");
              this.$router.go(-1);
            } else {
              this.$message.error("新增失败");
            }
          }
        }
      });
    },
    // 下载附件
    async downloadFile() {
      // const URL = "http://192.168.0.12:8085/";
      const url = this.caseDataInfo.fileName;
      axios({
        method: "GET",
        url: `${URL}/public/downloadUrlFile`,
        params: { urlStr: url },
        responseType: "blob",
        headers: {
          // 设置请求头
          Authorization: this.Authorization,
        },
      })
        .then((res) => {
          let blob = new Blob([res.data], {
            type: `application/msword`,
          });
          let href = window.URL.createObjectURL(blob); //创建新的URL表示指定的blob对象
          const a = document.createElement("a"); //创建a标签
          a.style.display = "none";
          a.href = href; // 指定下载'链接
          console.log(a.href);
          a.download = url.substring(url.lastIndexOf("/") + 1); //指定下载文件名
          a.click(); //触发下载
          URL.revokeObjectURL(a.href); //释放URL对象
          document.body.removeChild(a); //释放标签
        })
        .catch((err) => {
          //this.$message.error('文件下载失败')
        });
    },
    // 取消
    cancelItem() {
      this.$confirm("确认退出吗 ?", "提示")
        .then(() => {
          this.$router.go(-1);
        })
        .catch(() => {});
    },
    searchCountry(val) {
      this.caseDataInfo.country = val;
    },
    searchProvince(val) {
      this.caseDataInfo.province = val;
    },
    searchCity(val) {
      this.caseDataInfo.city = val;
    },
    //文件上传返回的参数
    fileKey(val) {
      this.caseDataInfo.fileName = val;
      this.caseDataInfo.fileNames = this.caseDataInfo.fileName.substring(
        this.caseDataInfo.fileName.lastIndexOf("/") + 1
      );
    },
    //文件上传成功返回的名字
    successUpload(val) {
      console.log(val);
    },
    handleClose(tag) {
      this.caseDataInfo.drafterList.splice(
        this.caseDataInfo.drafterList.indexOf(tag),
        1
      );
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = this.caseDataInfo.value;
      if (inputValue) {
        this.caseDataInfo.drafterList.push(inputValue);
      }
      this.inputVisible = false;
      this.caseDataInfo.value = "";
    },
  },
  // mounted() {
  //   document.getElementsByClassName("infoOne")[0].style.height =
  //     document.body.clientHeight - 200 + "px";
  // }
};
</script>

<style lang="less" scoped>
.infoOne {
  overflow: auto;
}
.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  .el-button {
    margin: 0 20px;
  }
}
.caseDataInfo {
  background-color: #fff;
  padding: 20px 20px 0 20px;
  .el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }
}
/deep/.el-upload--picture-card {
  width: 80px !important;
  height: 80px !important;
  line-height: 80px !important;
}
.policyData {
  display: flex;
  // justify-content: space-between;
}
.ess_people {
  display: flex;
  flex-direction: column;
  // overflow: hidden;
  margin: 20px 30px;
  align-content: center;

  .ess_people_list {
    width: 100%;
    margin: 4px 0;
    color: #7c7f8e;
    font-size: 12px;
  }
}
/deep/ .el-input-group__append,
.el-input-group__prepend {
  background-color: #fff;
  font-size: 20px;
}
</style>
